/* You can add global styles to this file, and also import other style files */

//// Set your brand colors
//$purple: #8a4d76;
//$pink: #fa7c91;
//$brown: #757763;
//$beige-light: #d0d1cd;
//$beige-lighter: #eff0eb;
//
//// Path to Bulma's sass folder
//@use "bulma/sass" with (
//  $family-primary: '"Nunito", sans-serif',
//  $grey-dark: $brown,
//  $grey-light: $beige-light,
//  $primary: $purple,
//  $link: $pink,
//  $control-border-width: 2px,
//  $input-shadow: none
//);

@import "bulma/bulma";
@import 'swiper/scss/zoom';


.message {
  --bulma-message-header-padding: 0.5em 1em;
  background: var(--bulma-background);
}

html {
  overflow: hidden; // hides vertical scrollbar
}

.grid {
  --bulma-grid-gap: 0;
}

.table-compact {
  font-size: 12px;
}

.button.is-tiny {
  font-size: 0.5rem;
}

.icon-smaller {
  height: 16px !important;
  width: 16px !important;
}

.icon-tiny {
  height: 10px !important;
  width: 10px !important;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.content-placeholder {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--bulma-grey-light);
  flex: 1;
}

.hide-overflow {
  overflow: hidden;
  //app-chart {
  //  canvas#chart {
  //    height: 92% !important;
  //  }
  //}
}

.dashboard-component-tabs li:first-child a {
  border-radius: 0 5px 0 0;
  border-left: none !important;
}

.dashboard-component-tabs li a {
  border-top: none !important;
}

.indicator {
  width: 0;
  height: 0;
  margin-right: 3px;

  &.indicator-asc {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid black;
  }

  &.indicator-desc {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 5px solid black;
  }
}

.sort-direction-indicator {
  height: 18px !important;
  width: 18px !important;
}

.dashboard-component-tabs {
  border-radius: 5px 5px 0 0;
  margin-bottom: 0 !important;
  background-color: var(--bulma-grey-light) !important;

  ul {
    border-bottom: none;

    li {
      a {
        background-color: hsl(0deg, 0%, 96%);

        &:hover {
          background-color: #fff !important;
        }
      }
    }
  }
}

.flex-spacer {
  flex: 1;
}

// overwriting swiper colours
.swiper-button-prev {
  color: white !important;
}

.swiper-button-next {
  color: white !important;
}

.swiper-pagination-bullet {
  background: white !important;
  opacity: 0.5 !important;
}

.swiper-pagination-bullet-active {
  background: white !important;
  opacity: 1.0 !important;
}

.json-viewer-wrapper {
  margin: 0 15px;
}

@mixin loader() {
  animation: spinAround .5s infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}

.loader-mixin {
  @include loader();
}
